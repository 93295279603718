import React from 'react'
import { ThemeProvider } from 'styled-components'

const theme = {
  fontSizes: {
    75: '0.75rem',
    100: '0.875rem',
    200: '1rem',
    300: '1.25rem',
    400: '1.5rem',
    500: '1.75rem',
    600: '2rem',
    700: '2.5rem',
  },
  radius: { md: '20px' },
  button: {
    paddingx: '16px',
    paddingy: '8px',
    borderRadius: '12px',
  },
  colors: {
    pink900: '#48000A',
    pink800: '#641a23',
    pink700: '#7f353b',
    pink600: '#985055',
    pink400: '#c68a8e',
    pink300: '#daa8ac',
    pink200: '#edc8cc',
    pink100: '#ffe9ec',

    brown900: '#83613e',
    brown800: '#95714e',
    brown700: '#a6825f',
    brown600: '#b79472',
    brown400: '#d8b89c',
    brown300: '#e6ccb3',
    brown200: '#f4dfcd',
    brown100: '#fff4e9',

    light500: '#8B8B8B',
    light400: '#A7A7A7',
    light300: '#C4C4C4',
    light200: '#E1E1E1',
    light100: '#FFFFFF',

    dark500: '#1C1C1C',
    dark400: '#282828',
    dark300: '#3F3F3F',
    dark200: '#575757',
    dark100: '#717171',
  },
  fonts: ['Montserrat', 'sans-serif'],
  sizes: {
    xs: '0.5rem',
    sm: '1rem',
    md: '2rem',
    lg: '3rem',
  },
}

type ThemeProps = {
  readonly children: JSX.Element
}

export const Theme = ({ children }: ThemeProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
