import FacebookLogo from '../../assets/svg/facebook.svg'
import MessengerLogo from '../../assets/svg/messenger.svg'
import InstagramLogo from '../../assets/svg/instagram.svg'
import PhoneLogo from '../../assets/svg/phone.svg'
import Mail from '../../assets/svg/enveloppe.svg'

export const socialNetWork = {
  facebook: {
    title: 'Facebook',
    src: FacebookLogo,
    url: 'https://www.facebook.com/profile.php?id=100089529634490',
  },
  messenger: {
    title: 'Messenger',
    src: MessengerLogo,
    url: 'https://www.messenger.com/t/100089499845402',
  },
  instagram: {
    title: 'Instagram',
    src: InstagramLogo,
    url: 'https://instagram.com/ceremonie.mpm?igshid=YmMyMTA2M2Y=',
  },
  phone: {
    src: PhoneLogo,
    title: '0748904036',
  },
  email: {
    src: Mail,
    title: 'ceremonie.mpm@gmail.com',
  },
}
