import React from 'react'
import styled from 'styled-components'
import { Title } from 'components/Title'

import LittleCurve from '../../../assets/svg/LittleCurve.svg'

import { services } from './constants'
import { MapArea } from './MapArea'

const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5rem;

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
const TitleWrapper = styled.div`
  width: 100%;
  height: 44px;
  position: relative;
  display: flex;
  align-items: center;
`
const TitleLine = styled.div`
  width: 100%;
  height: 22px;
  position: absolute;
  bottom: 0;
  background-color: ${({ theme: { colors } }) => colors.brown100};
  z-index: -1;
`

const Article = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
`

const Span = styled.span`
  color: ${({ theme: { colors } }) => colors.brown800};
  font-weight: bold;
  font-size: ${({ theme: { fontSizes } }) => fontSizes['100']};
  text-transform: uppercase;
  margin-top: 14px;
`

const Text = styled.p`
  color: ${({ theme: { colors } }) => colors.pink800};
`

const PriceText = styled.p`
  color: ${({ theme: { colors } }) => colors.pink800};
  margin: 0;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 8px 16px;
  color: ${({ theme: { colors } }) => colors.light100};
  font-size: ${({ theme: { fontSizes } }) => fontSizes['200']};
  background: ${({ theme: { colors } }) => colors.pink700};
  border-radius: 12px;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 0;
  }
`
const Body = styled.div`
  > * {
    margin-top: 2rem;
  }
`
const SpanBody = styled.span`
  font-weight: bold;
`
const Description = styled.p`
  margin: 5px 0 0;
`

const Image = styled.img`
  border-radius: 20px;
  width: 100%;
  height: auto;
  object-fit: contain;
`

const Curve = styled.img`
  display: none;

  @media (min-width: 900px) {
    display: block;
    width: 100%;
  }
`
// const Actu = styled.div`
//   width: 60%;
//   display: flex;
//   flex-direction: column;
//   background-color: ${({ theme: { colors } }) => colors.brown100};
//   border-radius: 20px;
//   padding: 2rem;
//   margin: 0 auto;
// `

type ServicesProps = {
  readonly openModal: (content: { title: string; body: JSX.Element }) => void
}

export const Services = (props: ServicesProps) => {
  const { openModal } = props

  const discoverService = (service: (typeof services)[number]) => {
    openModal({
      title: service.title,
      body: (
        <Body>
          {service.textBody.map(element => (
            <div key={element.step}>
              <SpanBody>{element.step} :</SpanBody>
              <Description>{element.text}</Description>
              {element.plus !== '' ? (
                <Description>
                  <SpanBody>Le petit + : </SpanBody>
                  {element.plus}
                </Description>
              ) : null}
            </div>
          ))}
          <div>
            <PriceText>Formule à partir de {service.price} €</PriceText>
            <PriceText>{service.payment}</PriceText>
          </div>
        </Body>
      ),
    })
  }

  const widthSize = window.innerWidth
  const title = widthSize < 900 ? 'Mes prestations' : 'Mes prestations dans le Sud-Ouest'

  return (
    <>
      <TitleWrapper id="prestations">
        <Title as="h2" style={{ marginLeft: '2rem' }}>
          {title}
        </Title>
        <TitleLine />
      </TitleWrapper>

      {/* <Actu>
        <Title as="h3">Prix spécial "Saint Valentin" ♡</Title>
        <Span>Pendant tout le mois de février 2023</Span>
        <Text>Profitez d'une réduction de 100€ sur chaque prestation qui vous intéresse !</Text>
      </Actu> */}

      <Section>
        {services.map(service => (
          <Article key={service.title}>
            <Image alt={service.alt} loading="lazy" src={service.source} />
            <Span>Cérémonie {service.type}</Span>
            <Title as="h3">{service.title}</Title>
            <Text>{service.text}</Text>
            <PriceText>À partir de {service.price}€</PriceText>
            <Wrapper>
              <Curve alt="Décoration" loading="lazy" src={LittleCurve} />
              <Button onClick={() => discoverService(service)} type="button">
                Découvrez la formule
              </Button>
            </Wrapper>
          </Article>
        ))}
        <MapArea />
      </Section>
    </>
  )
}
