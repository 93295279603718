import React from 'react'
import styled from 'styled-components'

import XIcone from '../assets/svg/cross.svg'

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
`
const Section = styled.div`
  position: relative;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.light100};
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  margin: 1.5rem;
  padding: 24px;
`

const Title = styled.h2`
  text-align: center;
  font-family: 'Playfair Display';
  color: ${({ theme: { colors } }) => colors.pink700};
`

const Wrapper = styled.div`
  color: ${({ theme: { colors } }) => colors.pink700};
  text-align: center;
  max-height: 30rem;
  overflow-y: auto;
`
const CloseIcone = styled.button`
  width: 20px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`
const Image = styled.img`
  width: 100%;
`

type ModalProps = {
  readonly closeModal: VoidFunction
  readonly content: { title: string; body: JSX.Element }
  readonly isOpen: boolean
}

export const Modal = (props: ModalProps) => {
  const {
    closeModal,
    content: { body, title },
    isOpen,
  } = props
  return (
    <div>
      {isOpen && (
        <Container onClick={closeModal}>
          <Section
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <CloseIcone onClick={closeModal}>
              <Image alt="Fermer la modale" loading="lazy" src={XIcone} />
            </CloseIcone>
            <Title>{title}</Title>
            <Wrapper>{body}</Wrapper>
          </Section>
        </Container>
      )}
    </div>
  )
}
