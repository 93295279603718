import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat';
    font-size: 1rem;
    line-height: 1.5;
    -moz-osx-font-smoothing: grayscale;
    margin: 48px 0 0;
  }
`
