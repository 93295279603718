import styled from 'styled-components'

export const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5rem;

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const Article = styled.article`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  figure {
    display: flex;
  }

  img {
    max-width: 80%;
    object-fit: cover;
    margin: 32px auto;
    border-radius: 20px;
  }

  @media (min-width: 900px) {
    width: calc(100% - 150px);
  }
  @media (min-width: 1200px) {
    width: calc(100% - 300px);
  }
`
export const ShortArticle = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const Text = styled.div`
  color: ${({ theme: { colors } }) => colors.pink800};
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 8px 16px;
  color: ${({ theme: { colors } }) => colors.light100};
  font-size: ${({ theme: { fontSizes } }) => fontSizes['200']};
  background: ${({ theme: { colors } }) => colors.pink700};
  border-radius: 12px;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
`
