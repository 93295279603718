import React from 'react'
import { Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import * as S from 'styles'
import { Article } from 'pages/Blog/Article'

import { Blog } from './pages/Blog'
import { Footer } from './components/Footer/Footer'
import { Navigation } from './components/Navigation'
import { Home } from './pages/Home'
import { Theme } from './provider/ThemesProvider'

export const App = () => {
  return (
    <Theme>
      <>
        <S.Grid>
          <Navigation />
          <Router>
            <Routes>
              <Route element={<Home />} path="/" />
              <Route element={<Blog />} path="blog" />
              <Route element={<Article />} path="blog/:slug" />
            </Routes>
            <Outlet />
          </Router>
        </S.Grid>
        <Footer />
      </>
    </Theme>
  )
}
