export const leftLinksArray = [
  { label: 'accueil', route: '/', type: 'link' },
  { label: 'à propos', route: '/#a-propos', type: 'link' },
]

export const rightLinksArray = [
  { label: 'prestations', route: '/#prestations', type: 'link' },
  { label: 'blog', route: '/blog', type: 'link' },
  { label: 'contactez-moi', route: '/#contact-footer', type: 'button' },
]
