import styled from 'styled-components'
import React from 'react'

import { socialNetWork } from './constants'

const Container = styled.section`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.brown100};
  > * {
    padding-top: 1rem;
  }
`

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;

  > * {
    margin-top: 1rem;
  }

  @media (min-width: 900px) {
    flex-direction: row;

    > * {
      margin-top: 0;
    }
  }
`
const Contact = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.pink700};
`
const Span = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.pink700};
`
const Image = styled.img`
  width: 2rem;
  margin-right: 8px;
`

const Copyright = styled.p`
  display: flex;
  justify-content: center;
  color: ${({ theme: { colors } }) => colors.pink800};
  padding-bottom: 1rem;
  margin: 0;
`

export const Footer = () => {
  const dateOfThisDay = new Date()
  const year = dateOfThisDay.getUTCFullYear()

  const { email, facebook, instagram, messenger, phone } = socialNetWork

  return (
    <Container id="contact-footer">
      <SocialWrapper>
        <Contact href={facebook.url} title={facebook.title}>
          <Image alt={facebook.title} loading="lazy" src={facebook.src} />
          {facebook.title}
        </Contact>
        <Contact href={messenger.url} title={messenger.title}>
          <Image alt={messenger.title} loading="lazy" src={messenger.src} />
          {messenger.title}
        </Contact>
        <Contact href={instagram.url} title={instagram.title}>
          <Image alt={instagram.title} loading="lazy" src={instagram.src} />
          {instagram.title}
        </Contact>
      </SocialWrapper>
      <Wrapper>
        <Span>
          <Image alt={phone.title} loading="lazy" src={phone.src} />
          {phone.title}
        </Span>
        <Span>
          <Image alt={email.title} loading="lazy" src={email.src} />
          {email.title}
        </Span>
      </Wrapper>
      <Copyright>
        <small>&copy; Mot pour Mot {year}</small>
      </Copyright>
    </Container>
  )
}
