import React, { useState } from 'react'

import { Modal } from '../../components/Modal'

import { About, Intro, Services } from './components'

export const Home = () => {
  const [modalContent, setModalContent] = useState<{ title: string; body: JSX.Element }>({
    body: null,
    title: null,
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const openModal = (content: { title: string; body: JSX.Element }) => {
    setIsOpen(true)
    setModalContent(content)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Intro />
      <About />
      <Services openModal={openModal} />
      <Modal closeModal={closeModal} content={modalContent} isOpen={isOpen} />
    </>
  )
}
