import { Title } from 'components/Title'
import React from 'react'
import styled from 'styled-components'

import { mapElement } from './constants'

const Section = styled.section`
  background-color: ${({ theme: { colors } }) => colors.brown200};
  border-radius: 20px;
`
const Container = styled.div`
  margin: 48px 24px;
`

const Article = styled.article`
  position: relative;
  display: flex;
  background-color: ${({ theme: { colors } }) => colors.brown100};
  border-radius: 20px;
  padding: 24px;
  margin-top: 24px;
`
const Text = styled.p`
  color: ${({ theme: { colors } }) => colors.pink700};
  text-align: ;
`
const Wrapper = styled.div`
  @media (min-width: 1000px) {
    > :first-child {
      width: 60%;
    }
  }
`

const Image = styled.img`
  display: none;

  @media (min-width: 1000px) {
    position: absolute;
    display: block;
    right: -20px;
    top: -10px;
    width: 45%;
  }
`

export const MapArea = () => {
  return (
    <Section>
      <Container>
        <Title as="h2">{mapElement.title}</Title>
        <Article>
          <Wrapper>
            <Text>{mapElement.firstText}</Text>
            <Text>{mapElement.secondText}</Text>
          </Wrapper>
          <Image
            alt="Carte de la Nouvelle-Aquitaine - Zone de déplacement"
            loading="lazy"
            src={mapElement.source}
          />
        </Article>
      </Container>
    </Section>
  )
}
