import castel from '../../../assets/jpg/castel.jpg'
import field from '../../../assets/jpg/field.jpg'
import baptism from '../../../assets/jpg/baptism.jpg'
import map from '../../../assets/svg/map.svg'

export const services = [
  {
    source: castel,
    alt: 'Prestation: “Juste ce qu’il faut” - Mariage - Cérémonie laïque',
    type: 'mariage',
    title: '“Juste ce qu’il faut”',
    text: `L’aventure commence avec un premier rendez-vous, qui sera le point de départ de l’écriture de votre cérémonie. Dès lors, je reste disponible pour vous jusqu’au jour J. Cela me permet d’apprendre à vous connaître et de créer un lien unique avec vous.
    Suivant vos souhaits et vos envies, je vous guide dans les choix de musiques, de rituels et peux vous apporter mon soutien dans l’écriture de vos vœux.
    Quelques semaines avant votre union, je vous présente la cérémonie créée et imaginée pour vous, et la réajuste autant de fois que nécessaire.
    Le jour-J, je suis présente pour officier votre cérémonie. Ainsi, vous avez juste ce qu’il faut pour célébrer votre amour à votre image.`,
    price: 750,
    payment: 'Paiement en 3 fois.',
    textBody: [
      {
        step: 'J-200',
        text: 'Au cours de notre première rencontre, vous me parlerez de vous, de votre histoire et de la cérémonie de vos rêves. Ces échanges me permettront d’apprendre à vous connaître et de concevoir par la suite votre cérémonie sur mesure. Je tiens à être disponible pour vous, nos échanges (téléphone, visio, sms) sont illimités et ce, jusqu’au jour J.',
        plus: '',
      },
      {
        step: 'J-100',
        text: 'Je prends contact avec vos proches (témoins, parents…) qui souhaitent intervenir au cours de la cérémonie, et auprès de vos prestataires (DJ, photographe, lieu de cérémonie. Cette prise de contact permet le bon déroulement de votre cérémonie.',
        plus: '',
      },
      {
        step: 'J-60',
        text: 'Je vous présente le déroulé de la cérémonie que j’ai imaginé pour vous (texte, musique, rituel). Nous réajustons la trame ensemble si nécessaire. Lorsque nous validons la trame, je la transmets aux prestataires.',
        plus: '',
      },
      {
        step: 'J-10',
        text: 'Lors de cette dernière étape nous revoyons ensemble les petits détails de votre cérémonie. Je recontacte également vos témoins et intervenants.',
        plus: '',
      },
      {
        step: 'Jour-J',
        text: 'Je suis présente à vos côtés et officie votre cérémonie.',
        plus: '',
      },
    ],
  },
  {
    source: field,
    alt: 'Prestation: “Et bien plus encore” - Mariage - Cérémonie laïque',
    type: 'mariage',
    title: '“Et bien plus encore”',
    text: `En plus d’écrire et construire votre cérémonie, je vous propose, mon accompagnement et mon savoir-faire dans la mise en scène de votre cérémonie, créant ainsi une atmosphère authentique et une ambiance unique.
    Vous avez déjà imaginé cent fois, voire mille fois ce moment. Un thème spécifique, une arche florale ? Laissez-moi vous suivre dans vos rêves les plus fous.
    Je vous présente la trame de votre cérémonie écrite pour vous et une planche d’inspiration, selon le thème ou le décor souhaité.
    Le jour-J, je m’occupe de toute la cérémonie et de la décoration. Laissez-vous surprendre lors de ce merveilleux jour.`,
    price: 1200,
    payment: 'Paiement en 3 fois.',
    textBody: [
      {
        step: 'J-200',
        text: 'Au cours de notre première rencontre, vous me parlerez de vous, de votre histoire et de la cérémonie de vos rêves. Ces échanges me permettront d’apprendre à vous connaître et de concevoir par la suite votre cérémonie sur mesure. Je tiens à être disponible pour vous, nos échanges (téléphone, visio, sms) sont illimités et ce, jusqu’au jour J.',
        plus: "Vous souhaitez un thème en particulier ou n'avez pas d'idées ? Nous y réfléchissons ensemble et définissons un décor à votre image.",
      },
      {
        step: 'J-100',
        text: 'Je prends contact avec vos proches (témoins, parents…) qui souhaitent intervenir au cours de la cérémonie, et auprès de vos prestataires (DJ, photographe, lieu de cérémonie. Cette prise de contact permet le bon déroulement de votre cérémonie.',
        plus: "Je me rends sur le lieu que vous avez choisi afin de concevoir votre décoration. Je vous soumets ensuite une planche d'inspiration.",
      },
      {
        step: 'J-60',
        text: 'Je vous présente le déroulé de la cérémonie que j’ai imaginé pour vous (texte, musique, rituel). Nous réajustons la trame ensemble si nécessaire. Lorsque nous validons la trame, je la transmets aux prestataires.',
        plus: "Je m'assure auprès de mes fournisseurs et partenaire du traitement des commandes et finalise la décoration.",
      },
      {
        step: 'J-10',
        text: 'Lors de cette dernière étape nous revoyons ensemble les petits détails de votre cérémonie. Je recontacte également vos témoins et intervenants.',
        plus: '',
      },
      {
        step: 'Jour-J',
        text: 'Je suis présente à vos côtés et officie votre cérémonie.',
        plus: "La veille ou le matin même j'installe le décor et le désinstalle le soir ou le lendemain de la cérémonie.",
      },
    ],
  },
  {
    source: baptism,
    alt: 'Prestation: “Bienvenue” - Baptême - Cérémonie laïque',
    type: 'baptême',
    title: '“Bienvenue”',
    text: `Une première rencontre est essentielle afin de créer une cérémonie qui le représente et représente les valeurs de votre famille. Au cours de ce premier rendez-vous, j’apprends à vous connaître (parents, enfants, parrain et marraine).
    Je reste disponible et à votre écoute jusqu’à la cérémonie. Nous nous rencontrons autant de fois que nécessaire.
    Après notre rencontre, je rédige la cérémonie de bienvenue de votre enfant et vous propose un rituel symbolisant son baptême.
    Je suis présente le jour J et officie la cérémonie.`,
    price: 500,
    payment: 'Paiement en 3 fois.',
    textBody: [
      {
        step: 'J-200',
        text: 'Après un premier échange par téléphone, je vous rencontre avec votre enfant, ainsi que ses futurs parrains et marraines. En apprendre plus sur vous, votre famille et votre souhait m’aideront à écrire la cérémonie de bienvenue de votre enfant. Je tiens à être disponible pour chacun de vous, nos échanges (téléphone, visio, sms) sont illimités et ce, jusqu’au jour J',
        plus: '',
      },
      {
        step: 'J-100',
        text: 'Je m’entretiens de nouveau avec les parrains et marraines uniquement, afin de concevoir avec eux la cérémonie qui symbolise pour eux, l’acte de devenir parrain et marraine.',
        plus: '',
      },
      {
        step: 'J-60',
        text: 'Je vous présente le déroulé de la cérémonie imaginée pour vous et votre enfant. Nous réajustons la trame ensemble si nécessaire. Je vous propose, selon vos envies un rituel qui vient matérialiser de façon symbolique le baptême de votre enfant.',
        plus: '',
      },
      {
        step: 'J-10',
        text: 'Lors de cette dernière étape nous revoyons ensemble les petits détails de votre cérémonie. Je recontacte également les parrains et marraines.',
        plus: '',
      },
      {
        step: 'Jour-J',
        text: 'Je suis présente à vos côtés et officie votre cérémonie.',
        plus: '',
      },
    ],
  },
]

export const mapElement = {
  title: 'Organisation de vos cérémonies à 100km autour de Bordeaux',
  source: map,
  firstText:
    'Née en Haute-Gironde, amoureuse éperdue de cette magnifique région, j’aime observer le soleil se coucher sur les rangs de vignes. J’aime laisser mon regard se plonger dans l’immense étendue de l’Atlantique, sans jamais être lassée. J’aime ces forêts de pins maritimes où la paix semble régner.',
  secondText:
    'Chaque mariage est unique, et chaque décor l’est aussi. Je me ferais un plaisir de célébrer votre union dans un rayon de 100 km autour de Bordeaux. ',
}
