import React from 'react'
import styled from 'styled-components'
import heart from 'assets/svg/heart.svg'
import flowersImage from 'assets/svg/BannerImage.svg'
import { Title } from 'components/Title'

const Section = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 24px;
  height: 100%;
  background-color: ${({ theme: { colors } }) => colors.brown100};
  border-radius: ${({ theme: { radius } }) => radius.md};
  margin-bottom: 64px;

  @media (min-width: 900px) {
    height: 325px;
    margin-bottom: 64px;
  }
`

const Article = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;
  grid-column: 2/10;

  @media (min-width: 900px) {
    margin-top: -3rem;
    grid-column: 2/6;
  }
`

const Text = styled.p`
  color: ${({ theme: { colors } }) => colors.pink600};
`

const Image = styled.img`
  display: none;
  margin-top: 3rem;
  width: 100%;

  @media (min-width: 900px) {
    display: block;
    grid-column: 7/10;
  }
`

const Decoration = styled.img`
  display: none;
  position: absolute;
  bottom: -65px;
  left: -35px;
  z-index: 1;
  height: 140px;

  @media (min-width: 900px) {
    display: block;
  }
`

export const Intro = () => {
  return (
    <Section id="accueil">
      <Article>
        <Title as="h1">La cérémonie de vos rêves</Title>
        <Text>
          Quelle belle preuve d’amour que de s’unir à son humain favori sur terre. Quelles que
          soient les raisons qui vous amènent à choisir la cérémonie laïque, ce choix est fait avec
          le cœur et avec amour.
        </Text>
      </Article>
      <Image alt="Décorations en fleurs séchées" loading="lazy" src={flowersImage} />
      <Decoration alt="Décorations" loading="lazy" src={heart} />
    </Section>
  )
}
