import React from 'react'
import styled from 'styled-components'

import { leftLinksArray, rightLinksArray } from '../routes/routes'
import Logo from '../assets/svg/Logo.svg'

const NavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 900px) {
    flex-direction: row;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const Link = styled.a`
  text-decoration: none;
  color: ${({ theme: { colors }, type }) => (type === 'link' ? colors.pink600 : colors.light100)};
  background-color: ${({ theme: { colors }, type }) =>
    type === 'link' ? colors.light100 : colors.pink700};
  margin: 0 0.5rem;
  padding: ${({ theme: { button } }) => `${button.paddingy} ${button.paddingx}`};
  border-radius: ${({ theme: { button } }) => button.borderRadius};
`

const Image = styled.img`
  width: 150px;
  height: auto;
  order: -1;

  @media (min-width: 900px) {
    width: 220px;
    height: auto;
    order: 0;
  }
`

export const Navigation = () => {
  const links = (array: typeof leftLinksArray) =>
    array.map((element: { label: string; route: string; type: string }) => (
      <Link href={element.route} key={element.label} type={element.type}>
        {element.label}
      </Link>
    ))

  return (
    <NavContainer>
      <LinkWrapper>{links(leftLinksArray)}</LinkWrapper>
      <Image alt="Mot pour mot" loading="lazy" src={Logo} />
      <LinkWrapper>{links(rightLinksArray)}</LinkWrapper>
    </NavContainer>
  )
}
