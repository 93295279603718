import styled from 'styled-components'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(5, auto);
  grid-column-gap: 24px;
  grid-row-gap: 68px;
  margin: 0 auto;
  max-width: 1440px;

  > * {
    grid-column: 2/12;
  }
`
