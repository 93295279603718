import { Title } from 'components/Title'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as S from '../styles'

const apiUrl = 'https://public-api.wordpress.com/rest/v1.1/sites/ceremoniempm.wordpress.com/posts/'

export const Article = () => {
  const [article, setArticle] = useState(null)
  const params = useParams()

  useEffect(() => {
    fetch(`${apiUrl}slug:${params.slug}`)
      .then(response => response.json())
      .then(data => setArticle(data))
  }, [params])

  if (!article) return null

  return (
    <S.Article>
      <Title>{article.title}</Title>
      <S.Text dangerouslySetInnerHTML={{ __html: article.content }} />
    </S.Article>
  )
}
