import React from 'react'
import styled from 'styled-components'
import about from 'assets/jpg/about.jpg'
import { Title } from 'components/Title'

const Section = styled.section`
  display: grid;
  grid-column-gap: 24px;
  grid-template-areas:
    'image'
    'text';

  @media (min-width: 900px) {
    grid-template-areas: 'image text';
  }
`
const TitleWrapper = styled.div`
  width: 100%;
  height: 44px;
  position: relative;
  display: flex;
  align-items: center;
`
const TitleLine = styled.div`
  width: 100%;
  height: 22px;
  position: absolute;
  bottom: 0;
  background-color: ${({ theme: { colors } }) => colors.brown100};
  z-index: -1;
`

const Article = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: text;
  margin: 0 auto;
  text-align: justify;

  @media (min-width: 900px) {
    margin: 0 4rem;
  }
`

const Image = styled.img`
  width: 100%;
  grid-area: image;
  border-radius: 20px;
  height: 300px;
  margin-bottom: 2rem;
  object-fit: cover;

  @media (min-width: 900px) {
    height: auto;
  }
`

const Paragraph = styled.p`
  color: ${({ theme: { colors } }) => colors.pink700};
`

export const About = () => {
  return (
    <Section id="a-propos">
      <Image
        alt="Officiante de cérémonie - Cérémonie laïque - Mariage - Baptême"
        loading="lazy"
        src={about}
      />
      <Article>
        <TitleWrapper>
          <Title as="h2" style={{ marginLeft: '2rem' }}>
            Officiante de cérémonie
          </Title>
          <TitleLine />
        </TitleWrapper>
        <Paragraph>
          Vous le savez sans doute, la cérémonie laïque n’a pas de valeur légale aux yeux de la loi
          française. Et pourtant, elle n’en demeure pas moins{' '}
          <strong> symbolique et chargée d’émotions </strong>. Cette cérémonie n’a comme limite que
          vos souhaits, le mot d’ordre est : « liberté ».
        </Paragraph>

        <Paragraph>
          Qu’elle se fasse dans l’intimité la plus totale ou dans la chaleur de votre entourage,{' '}
          <strong>votre cérémonie est créée sur mesure</strong>, pour faire de ce moment un souvenir
          magique, suspendu hors du temps. La décoration, le lieu, les vœux, les rituels, tout,
          absolument tout vous appartient.
        </Paragraph>

        <Paragraph>
          Ce moment se doit d’être à votre image, unique et authentique, que vous célébriez un{' '}
          <strong>mariage</strong>, un <strong>PACS</strong> ou un{' '}
          <strong>renouvellement de vœux</strong>.
        </Paragraph>

        <Paragraph>
          Mon rôle: vous proposer <strong>la cérémonie de vos rêves</strong> et vous accompagner
          dans la célébration de votre engagement en retranscrivant pour vous,{' '}
          <strong>votre histoire, votre amour et vos valeurs</strong>.
        </Paragraph>

        <Paragraph>
          Je saurais vous proposer avec professionnalisme, une cérémonie à la hauteur de vos
          attentes et plus encore. Votre mariage fera partie des plus beaux jours de votre vie,
          écrivons ce jour ensemble.
        </Paragraph>
      </Article>
    </Section>
  )
}
