import styled from 'styled-components'

const TAG_NAMES = {
  h1: '700',
  h2: '600',
  h3: '500',
  h4: '400',
  h5: '300',
}

const TAG_NAMES_MOBILE = {
  h1: '600',
  h2: '500',
  h3: '400',
  h4: '300',
  h5: '200',
}

export const Title = styled.div<{ as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' }>`
  font-family: 'Playfair Display';
  font-size: ${({ as, theme: { fontSizes } }) =>
    fontSizes[TAG_NAMES_MOBILE[as]] || fontSizes[TAG_NAMES_MOBILE['h1']]};
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.pink700};
  margin: 0;
  line-height: 1.2;

  @media (min-width: 900px) {
    font-size: ${({ as, theme: { fontSizes } }) =>
      fontSizes[TAG_NAMES[as]] || fontSizes[TAG_NAMES['h1']]};
  }
`
