import { Title } from 'components/Title'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import * as S from './styles'

const apiUrl = 'https://public-api.wordpress.com/rest/v1.1/sites/ceremoniempm.wordpress.com/posts/'

export const Blog = () => {
  const [articles, setArticles] = useState([])

  useEffect(() => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => setArticles(data?.posts))
  }, [])

  return (
    <S.Section>
      {articles?.map(article => (
        <S.ShortArticle key={article.slug}>
          <Title as="h2">{article.title}</Title>
          <S.Text dangerouslySetInnerHTML={{ __html: article.excerpt }} />
          <Link to={article.slug}>Lire l'article</Link>
        </S.ShortArticle>
      ))}
    </S.Section>
  )
}
